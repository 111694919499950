// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import { useLocation } from '@reach/router';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import Icon from '@brighthr/component-icon';
import CopyBlock from '../components/CopyBlock';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import PersonProfile from '../components/CMSBlocks/PersonProfile';
import FilterButtons from '../components/FilterButtons';
import SEO from '../components/SEO';
import Peter from '../images/Peter-Done-hero.png';
import Hero from '../components/CMSBlocks/Hero';
import useFilter from '../hooks/useFilter';

const OurSpokespeople = ({ data }) => {
	const { quoteTileNode } = data.datoCmsOurSpokespeoplePage;
	const ref = useRef();

	const allEntries = data.allDatoCmsOurPeoplePage.nodes;
	const {
		updateFilter,
		changeFilter,
		filteredEntries,
		filters,
		isMobile,
		parsedEntries,
		resetFilters,
	} = useFilter({
		allEntries,
		multiOption: 'associatedCountries',
		ref,
	});

	const pageContent = useRef(null);
	const location = useLocation();

	return (
		<>
			<SEO path={location.pathname} title="Our Spokespeople" />
			<Hero bgImg={Peter} title="Our Spokespeople" fullWidth />
			<section>
				<Container className="!px-0">
					<div
						className="relative flex flex-col-reverse lg:pt-24 lg:flex-row brighthr"
						ref={pageContent}
					>
						<div className="w-full lg:w-3/4">
							<div
								className="flex flex-row w-full pt-2 pb-8 my-3 overflow-x-scroll overflow-y-hidden md:overflow-x-auto md:grid md:grid-cols-3 md:my-0 md:py-0 md:gap-y-4 snap-mandatory snap-x scroll-smooth"
								ref={ref}
							>
								{filteredEntries.map((person, i) => (
									<React.Fragment key={nanoid()}>
										<div className="flex w-full">
											<PersonProfile
												info={person}
												className="border-2 snap-center group border-secondary-100 md:border-none"
											/>
										</div>
										{filteredEntries.length >= 5 && i === 5 && !isMobile && (
											<div className="justify-center my-3 col-span-full md:flex ">
												<div
													className={cn(
														`lg:border-l-4 border-brand-200 !text-sm  !md:text-base md:px-6 px-3 md:ml-6  w-full lg:my-8 md:pr-8 pb-6 lg:w-9/12`
													)}
												>
													<CopyBlock>
														{quoteTileNode.childMdx.body}
													</CopyBlock>
												</div>
											</div>
										)}
									</React.Fragment>
								))}
							</div>
							{filteredEntries?.length === 0 && (
								<div className="flex flex-col items-center justify-center mb-8">
									<h2 className="text-xl md:text-2xl">No results.</h2>
									<button
										type="button"
										onKeyDown={resetFilters}
										onClick={resetFilters}
										className="text-brand-200"
									>
										Clear filters and search again
									</button>
								</div>
							)}
							<div className="flex justify-center px-4 md:hidden">
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += -300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-left"
									/>
								</button>
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += 300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-right"
									/>
								</button>
							</div>
							{(filteredEntries.length <= 5 || isMobile) && (
								<div
									className="flex justify-center my-3 col-span-full "
									key={nanoid()}
								>
									<div
										className={cn(
											`lg:border-l-4 border-brand-200 !text-sm  !md:text-base md:px-6 px-3 md:ml-6  w-full my-8 md:pr-8 pb-6 lg:w-9/12`
										)}
									>
										<CopyBlock>{quoteTileNode.childMdx.body}</CopyBlock>
									</div>
								</div>
							)}
						</div>
						<aside className="w-full px-4 py-0 bg-white lg:sticky lg:w-1/4 md:shadow-none md:h-fit md:px-2 md:my-8 md:mt-2 md:overflow-hidden top-16 md:top-20">
							<div className="z-30 flex flex-col-reverse pb-2 pointer-events-auto lg:flex-col md:bg-transparent">
								<FilterButtons
									className="lg:block lg:my-4"
									changeFilter={changeFilter}
									filters={filters}
									allEntries={parsedEntries}
									updateFilter={updateFilter}
									resetFilters={resetFilters}
								/>
							</div>
						</aside>
					</div>
				</Container>
			</section>
		</>
	);
};

export const query = graphql`
	query OurSpokespeople {
		datoCmsOurSpokespeoplePage {
			quoteTileNode {
				childMdx {
					body
				}
			}
		}
		allDatoCmsOurPeoplePage(
			filter: { spokesperson: { eq: true }, personName: { ne: "" } }
			sort: { fields: position, order: ASC }
		) {
			nodes {
				...DatoCMSOurPeopleFragment
			}
		}
	}
`;

const WrappedOurSpokespeople = (props) => (
	<DefaultLayout fullWidth>
		<OurSpokespeople {...props} />
	</DefaultLayout>
);

export default WrappedOurSpokespeople;
